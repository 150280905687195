import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blogfiveimg from '../../assets/images/icao.jpeg';
import img1 from '../../assets/images/1.jpeg';
import img2 from '../../assets/images/2.jpeg';
import img3 from '../../assets/images/3.jpeg';

function Blog5() {

    const { t } = useTranslation();

    return (
        <div className='blogs'>
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,
                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}>
                <div className='blogs-head'>
                    <h1>{t("Blogsix_header")}</h1>
                </div>
                <img src={blogfiveimg} alt="vafa blog" />
                <div className='blogs-exp'><p>
                    <img src={img1} style={{ maxWidth: '100%', height: 'auto' }} alt="" /><br/>
                    <img src={img2} style={{ maxWidth: '100%', height: 'auto' }} alt="" /><br/>
                    <img src={img3} style={{ maxWidth: '100%', height: 'auto' }} alt="" /><br/>
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog5