import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blogtwoimg from '../../assets/images/blogtwoimg.jpg'

import '../../App.css'
import '../../style.scss'

function Blog2() {

    const { t } = useTranslation();

    return (
        <div className='blogs'>
            <Header />
            <motion.div className='blogs-main'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}
            >
                <div className='blogs-head'>
                    <h1>{t("Blogtwo_header")}</h1>
                </div>
                <img src={blogtwoimg} alt="vafa blog" />
                <div className='blogs-exp'><p>
                    {t("Blogtwo_title_1")} <br /> <br />
                    {t("Blogtwo_title_2")} <br /> <br />
                    {t("Blogtwo_title_3")} <br /> <br />
                    {t("Blogtwo_title_4")} <br /> <br />
                    {t("Blogtwo_title_5")} <br /> <br />
                    {t("Blogtwo_title_6")} <br /> <br />
                    {t("Blogtwo_title_7")} <br /> <br />
                </p></div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Blog2