import React from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Blogcard from '../../Components/Blogcard'
import WhatsAppButton from '../../Components/WhatsAppButton';

import blog1 from '../../assets/images/blog1img.jpg'
import blog2 from '../../assets/images/blog2img.jpg'
import blog3 from '../../assets/images/blog3img.jpg'
import blog4 from '../../assets/images/blog4.jpg'
import blog5 from '../../assets/images/blog5img.jpg'
import blog6 from '../../assets/images/icao.jpeg'

function Bloghome() {

    const { t } = useTranslation();

    const carddata = [{
        img: blog1,
        head: t("blog1_head"),
        date: t("blog1_date"),
        cover: t("blog1_cover"),
        editor: "Vafa",
    },
    {
        img: blog2,
        head: t("blog2_head"),
        date: t("blog2_date"),
        cover: t("blog2_cover"),
        editor: " Vafa "
    },
    {
        img: blog3,
        head: t("blog3_head"),
        date: t("blog3_date"),
        cover: t("blog3_cover"),
        editor: " Vafa "
    },
    {
        img: blog4,
        head: t("blog4_head"),
        date: t("blog4_date"),
        cover: t("blog4_cover"),
        editor: " Vafa "
    },
    {
        img: blog5,
        head: t("blog5_head"),
        date: t("blog5_date"),
        cover: t("blog5_cover"),
        editor: " Vafa "
    },
    {
        img: blog6,
        head: t("blog6_head"),
        date: t("blog6_date"),
        cover: t("blog6_cover"),
        editor: " Vafa "
    }
    ]
    return (
        <div className='bloghome'>
            <Header />
            <motion.div className='bloghome-main-con'
                initial={{
                    y: 100,
                    opacity: 0.2,

                }}
                whileInView={{
                    y: 1,
                    opacity: 1,
                }}
                transition={{
                    type: "spring",
                    stiffness: 50,
                    damping: 10,
                    duration: 2
                }}

            >
                <div className='bloghome-hr-con'>
                    <Blogcard
                        img={carddata[0].img}
                        head={carddata[0].head}
                        date={carddata[0].date}
                        cover={carddata[0].cover}
                        editor={carddata[0].editor}
                        link="/Blogone"
                    />
                    <Blogcard
                        img={carddata[1].img}
                        head={carddata[1].head}
                        date={carddata[1].date}
                        cover={carddata[1].cover}
                        editor={carddata[1].editor}
                        link="/Blogtwo"
                    />
                    <Blogcard
                        img={carddata[2].img}
                        head={carddata[2].head}
                        date={carddata[2].date}
                        cover={carddata[2].cover}
                        editor={carddata[2].editor}
                        link="/Blogthree"
                    />
                </div>
                <div className='bloghome-hr-con'>
                    <Blogcard
                        img={carddata[3].img}
                        head={carddata[3].head}
                        date={carddata[3].date}
                        cover={carddata[3].cover}
                        editor={carddata[3].editor}
                        link="/Blogfour"
                    />
                    <Blogcard
                        img={carddata[4].img}
                        head={carddata[4].head}
                        date={carddata[4].date}
                        cover={carddata[4].cover}
                        editor={carddata[4].editor}
                        link="/Blogfive"
                    />
                    <Blogcard
                        img={carddata[5].img}
                        head={carddata[5].head}
                        date={carddata[5].date}
                        cover={carddata[5].cover}
                        editor={carddata[5].editor}
                        link="/Blogsix"
                    />

                </div>
            </motion.div>
            <WhatsAppButton />
            <Footer />
        </div>
    )
}

export default Bloghome