import React, { useRef, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";



import Header from "../Components/Header.js";
import Footer from "../Components/Footer.js";
import WhatsAppButton from '../Components/WhatsAppButton';
import Slider from '../Components/Slider.js';


// import vafaTopImage from "../assets/images/vafatopImage.jpg";
import educationTop from "../assets/images/egitimTakvim.png";
import steps from "../assets/images/steps.png";
import human from "../assets/images/educationHuman.png";
import pilot from "../assets/images/pilot.png";
import similator from "../assets/images/similator.png";
import picclub from "../assets/images/picclub.png";
import build from "../assets/images/build.png";
import cessena from "../assets/images/cessna.png";
import piper from "../assets/images/piper.png";
import piper2 from "../assets/images/piper2.png";
import tecnam from "../assets/images/tecnamimg.png";
import psim from "../assets/images/psim.png";
import greenLine from "../assets/images/greenLine.png";
import blogF from "../assets/images/blogFF.png";
import popup1 from "../assets/images/popup.jpeg";






const PopUp = ({ handleClose }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [popup1,];

  const handleImageClose = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      handleClose();
    }
  };

  return (
    <div className="popup">
      <div className="popup-overlay" onClick={handleImageClose}></div>
      <div className="popup-content">
        <button className="close-button" onClick={handleImageClose}>X</button>
        <img className="popup-image" src={images[currentImage]} alt="Popup Image" />
      </div>
    </div>
  );
};



function Home() {


  const [isPopUpOpen, setPopUpOpen] = useState(false); // Otomatik olarak açık
  const handleClosePopUp = () => {
    setPopUpOpen(false);
  };





  const { t } = useTranslation();
  const scrollRef = useRef(null)



  return (
    <div className="home-container">
      <Header />

      <motion.div
        initial={{ opacity: 0.2, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
        className=""
      >
        <Slider />



      </motion.div>







      {/* POPUP AÇ KAPA */}
      {isPopUpOpen && <PopUp handleClose={handleClosePopUp} />}

      <motion.div className="vafa-education"
        viewport={{ once: true }}
        initial={{
          y: 100,
          opacity: 0.2,
        }}

        whileInView={{
          y: 1,
          opacity: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 50,
          damping: 10,
          duration: 5
        }}
      >
        {/* <div className="education-top">
          <img src={educationTop} alt="" />
          <p>{t("training_calendar")}</p>
        </div> */}
        <div className="education-bot"

        >
          {/* <div className="education-bot-left">
            <div className="education-frame">
              <h1> {t("ppl_home")} </h1>
              <h5>{t("home_date")}</h5>
            </div>
            <Link to="/Ppl">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("information_button")}</motion.button>
            </Link>
          </div>
           <div className="education-bot-left">
            <div className="education-frame">
              <h1>{t("atp_home")}</h1>
              <h5>{t("home_date")}</h5>
            </div>
            <Link to="/Atp">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("information_button")}</motion.button>
            </Link>
          </div> */}
        </div>
      </motion.div>
      {/* <div className="vafa-tagline1">
        <h1 className="vafa-tagline">{t("tagline")}</h1>
    </div> */}
      <div id="academi" className="vafa-academia">
        <motion.div className="academia-top"
          viewport={{ once: true }}
          initial={{
            y: 100,
            opacity: 0.2,

          }}
          whileInView={{
            y: 1,
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 50,
            damping: 10,
            duration: 5
          }}
        >

          <div id="academi" className="vafa-academia">
            <motion.div className="academia-top"
              viewport={{ once: true }}
              initial={{ y: 100, opacity: 0.2 }}
              whileInView={{ y: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 50, damping: 10, duration: 5 }}
            >
              <h2>{t("training_calendar")}</h2>
              <h3>{t("training_calendar_sub")}</h3>
              <h4>
                {/*  {t("training_calendar_date4")} &#8987; 
                <br /> <br /> {t("training_calendar_date5")} &#8987;*/}
                <br /> <br /> {t("training_calendar_date6")} &#8987;
              </h4>
              <h4>{t("training_calendar_info")}</h4>
              <h5>{t("academy_subtitle")}</h5>
            </motion.div>
          </div>

          {/* <Link to="/pricing">
            <motion.button
              whileHover={{
                scale: 1.2,
              }}
              whileTap={{
                scale: 0.9
              }}
            > {t("price_button")}</motion.button>
          </Link> */}
        </motion.div>
        <motion.div className="academia-bottom"
          viewport={{ once: true }}
          initial={{
            opacity: 0.0,
          }}
          whileInView={{
            opacity: 1,
          }}
          transition={{
            duration: 1
          }}
        >
          <img src={steps} alt="Yol Haritası" />
          <img className="human" src={human} alt="İnsan" />
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: 100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame1">
            <h1>{t("academy_line_title1")}</h1>
            <Link to="/ppl" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: -100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame2">
            <h1>{t("night_info_header")}</h1>
            <Link to="/night" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: 100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame3">
            <h1>{t("academy_line_title3")}</h1>
            <Link to="/pic" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: 100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame4">
            <h1>{t("academy_line_title4")}</h1>
            <Link to="/atp" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: -100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame5">
            <h1>{t("academy_line_title5")}</h1>
            <Link to="/ir" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: 100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame6">
            <h1>{t("academy_line_title6")}</h1>
            <Link to="/cpl" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <motion.div
            // viewport={{ root: scrollRef }}
            // initial={{
            //   x: 100,
            //   opacity: 0.2,
            // }}
            // whileInView={{
            //   x: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   type: "spring",
            //   stiffness: 50,
            //   damping: 10,
            //   duration: 2
            // }}
            className="frame7">
            <h1>{t("academy_line_title7")}</h1>
            <Link to="/me" >
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </motion.div>
          <img className="pilot" src={pilot} alt="Pilot" />


        </motion.div>
        <br />
      </div>

      <motion.div id="plus" className="vafa-plus1"
        viewport={{ once: true }}
        initial={{
          y: 100,
          opacity: 0.2,

        }}
        whileInView={{
          y: 1,
          opacity: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 50,
          damping: 10,
          duration: 5
        }}
      >
        <div className="plus-title">
          <h1> {t("our_academi")} </h1>
        </div>
        <div className="extras">
          <div className="extra">
            <div className="exrtaImg">
              {/* <img src={similator} alt="" /> */}
            </div>
            <h3>{t("tbo_header")}</h3>
            <Link to="/tbo">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="extra">
            <div className="extraImg">
              {/* <img src={picclub} alt="" /> */}
            </div>

            <h3>{t("mcc_header")}</h3>
            <Link to="/mcc">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          {/* <div className="extra">
            <div className="extraImg">
              <img src={build} alt="" />
            </div>
            <h3>{t("our_privileges_con3")}</h3>
            <Link to="/housing">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div> */}
        </div>
      </motion.div>

      {/* ayrıcalık aşşağısı */}

      <motion.div id="plus" className="vafa-plus"
        viewport={{ once: true }}
        initial={{
          y: 100,
          opacity: 0.2,

        }}
        whileInView={{
          y: 1,
          opacity: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 50,
          damping: 10,
          duration: 5
        }}
      >
        <div className="plus-title">
          <h1> {t("our_privileges")} </h1>
        </div>
        <div className="extras">
          <div className="extra">
            <div className="exrtaImg">
              <img src={similator} alt="" />
            </div>
            <h3>{t("our_privileges_con1")}</h3>
            <Link to="/simulation">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="extra">
            <div className="extraImg">
              <img src={picclub} alt="pic club" />
            </div>

            <h3>{t("our_privileges_con2")}</h3>
            <Link to="/picservice">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          {/* <div className="extra">
            <div className="extraImg">
              <img src={build} alt="" />
            </div>
            <h3>{t("our_privileges_con3")}</h3>
            <Link to="/housing">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div> */}
        </div>
      </motion.div>



      <motion.div
        viewport={{ once: true }}
        initial={{
          y: 100,
          opacity: 0.2,
        }}
        whileInView={{
          y: 1,
          opacity: 1,
        }}
        transition={{
          type: "spring",
          stiffness: 50,
          damping: 10,
          duration: 2
        }}
        id="filo" className="filoCon">
        <h1> {t("our_fleet")} </h1>
        <div className="filo"></div>
        <motion.div
          viewport={{ once: true }}
          initial={{
            y: 100,
            opacity: 0.2,
          }}
          whileInView={{
            y: 1,
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 50,
            damping: 10,
            duration: 2
          }}
          className="planes">
          <div className="plane">
            <img src={cessena} alt="Cessna 172S Skyhawk" />
            <h3>Cessna 172S Skyhawk</h3>
            <h5>(172S 2015 MODEL)</h5>
            <Link to="cesna">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="plane">
            <img src={piper} alt="Piper Arrow PA-28R" />
            <h3>Piper Arrow PA-28R</h3>
            <h5>(201TC-VYZ  2016 MODEL)</h5>
            <Link to="arrow">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="plane">
            <img src={piper2} alt="Piper Seminole PA-44" />
            <h3>Piper Seminole PA-44 </h3>
            <h5>(TC-GDF 2015 MODEL)</h5>
            <h5>(TC-GDG 2015 MODEL)</h5>
            <Link to="seminole">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="plane">
            <img src={tecnam} alt="Tecnam P2008JC" />
            <h3>Tecnam P2008JC </h3>
            <h5>(TC- UEG  2020 MODEL )</h5>
            <h5>(TC-UEU  2019 MODEL)</h5>
            <Link to="tecnam">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
          <div className="plane">
            <img src={psim} alt="ALSIM ALX Simülatör" />
            <h3> {t("alsima_header")} </h3>
            <h5>(ALX-95  2020 MODEL)</h5>
            <Link to="alsima">
              <motion.button
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{
                  scale: 0.9
                }}
              >{t("examine_button")}</motion.button>
            </Link>
          </div>
        </motion.div>
      </motion.div>
      <motion.div className="blog"
        viewport={{ once: true }}
        initial={{
          opacity: 0.0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5
        }}
      >
        <h1>
          BL<motion.span
            viewport={{ root: scrollRef }}
            initial={{
              color: "#fff",
            }}
            whileInView={{
              color: "#b8ce0f",
            }}
            transition={{
              duration: 1,
              delay: 0.5
            }}
          >O</motion.span>G
        </h1>
        <div className="blogCon">
          {/* <h2>{t("blog_title1")}</h2>
          <img src={greenLine} alt="" /> */}
          <h2>{t("blog_title2")}</h2>
          <img src={greenLine} alt="Yeşil Çizgi" />
          <h2>{t("blog_title3")}</h2>
        </div>
        <Link to="/bloghome">
          <motion.button className="blog-buttton"
            whileHover={{
              scale: 1.2,
            }}
            whileTap={{
              scale: 0.9
            }}
          >
            <motion.img viewport={{ root: scrollRef }}
              initial={{
                x: -100,
                opacity: 0.2,
              }}
              whileInView={{
                x: 1,
                opacity: 1,
              }}
              transition={{
                type: "spring",
                stiffness: 50,
                damping: 10,
                duration: 5,
                delay: 0.6
              }} src={blogF} alt="" />
            <h6>{t("go_blog_button")}</h6>
          </motion.button>
        </Link>
      </motion.div>
      {/* <motion.div className="blog raporlama"
        viewport={{ once: true }}
        initial={{
          opacity: 0.0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5
        }}
      >
        <h1>
        {t("go_rapor_header")}
        </h1>
        <br/><br/>
        <Link to="https://www.google.com">
          
          <motion.button className="blog-buttton"
            whileHover={{
              scale: 1.2,
            }}
            whileTap={{
              scale: 0.9
            }}
          >
            <motion.img viewport={{ root: scrollRef }}
              initial={{
                x: -100,
                opacity: 0.2,
              }}
              whileInView={{
                x: 1,
                opacity: 1,
              }}
              transition={{
                type: "spring",
                stiffness: 50,
                damping: 10,
                duration: 5,
                delay: 0.6
              }} src={blogF} alt="" />
            <h6>{t("go_rapor_btn")}</h6>
          </motion.button>
        </Link>
      </motion.div> */}
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default Home;